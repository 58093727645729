import React from "react";
import { createChatBotMessage, Chatbot } from "react-chatbot-kit";
import { Dialog, DialogTitle, Box } from "@mui/material";
import { useState } from "react";
import "chatstyles.css";
import BotAvatar from "assets/bot.svg";

const CoBotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: "none" }}
      >
        <img alt="BotAvatar" src={BotAvatar} />
      </div>
    </div>
  );
};


/*
What industry best describes your organization?
Do you have a person in your organization responsible for Processes/Compliance/Cybersecurity?
Please select the person responsible from this list.
Do you have written policies and procedures for your organization?
Do you allow users to use their own devices for work?
Do you have a written policy for BYOD?
Do you have a written policy for remote workers?
Do you have a written policy for password management?

*/



const config = {
  botName: "KnowBS",
  lang: "en",

  initialMessages: [
    createChatBotMessage(
      "Hi! I'm here to help populate the portal with your organization's current technology profile. ",
      {
        withAvatar: true,
        delay: 0,
      }
    ),
    createChatBotMessage(
      "To do this I will ask basic broad questions and only get specific as needed. I'm very efficient and I too want to avoid a long boring survey.",
      {
        withAvatar: true,
        delay: 2500,
      }
    ),
    createChatBotMessage(
      "First, do you have a person in your organization responsible for responsible for Processes/Compliance/Cybersecurity?",
      {
        withAvatar: true,
        delay: 6500,
      }
    ),
  ],
  customComponents: { botAvatar: (props) => <CoBotAvatar {...props} /> },
};

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {},
        });
      })}
    </div>
  );
};

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    console.log(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

const KnowBee = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Chatbot
      config={config}
      messageParser={MessageParser}
      actionProvider={ActionProvider}
    />
  );
};

export default KnowBee;
