import { Box } from "@mui/material";

export default function Notifications() {
  return (
    <Box m="30px 30px" height="75vh">
      <h1>Coming Soon</h1>
      <Box m="30px 30px"></Box>
    </Box>
  );
}
