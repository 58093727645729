import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  HomeOutlined,
  ChevronLeft,
  ChevronRightOutlined,

} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { tokens } from "../theme";
import ScoreBox from "./ScoreBox";
import logoImage from "assets/logo.png";
import logoDarkImage from "assets/logo-dark.png";
import { useProfile } from "utils/profile"

const navItems = [
  {
    text: "Dashboard",
    path: "",
    icon: <HomeOutlined />,
  },
  
];

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const profile = useProfile();
  const user = profile.user;

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  useEffect(() => {
    setIsSidebarOpen(isNonMobile);
  }, [isNonMobile]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 1rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mr="15px"
                ></Box>
                {theme.palette.mode === "dark" ? (
                  <Box
                    component="img"
                    alt="KnowBreach Solutions"
                    src={logoDarkImage}
                    height="42px"
                    width="140px"
                  />
                ) : (
                  <Box
                    component="img"
                    alt="KnowBreach Solutions"
                    src={logoImage}
                    height="42px"
                    width="140px"
                  />
                )}
              <Typography variant="h5">
                CleaRisk&reg; Portal
              </Typography>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, path, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "1.2rem 0 1rem 2.2rem", color: "#63cae1" }}>
                      {text}
                    </Typography>
                  );
                }
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate("/" + path);
                        setActive(path);
                      }}
                      sx={{
                        backgroundColor:
                          active === path ? colors.primary[400] : "transparent",
                        color: "#4582c3",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "1.2rem",
                          mr: "0",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{ ml: "0"}}/>
                      {active === path && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
