import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState, useEffect } from "react";

export const useProfile = () => {
  const user = useUser();
  const supabase = useSupabaseClient();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const domain_name = user?.email.split("@")[1];
    // need to set domain to impersonated domain if impersonated

    async function getUserInfo() {
      try {
        
        let {
          data: userData,
          error: userError,
          status: userStatus,
        } = await supabase.from("users").select(`*`).eq("id", user?.id).single();
        if (userError && userStatus !== 406) {
          throw userError;
        }
        let roles = [];
        roles = userData.roles;

        let {
          data: peopleData,
          error: peopleError,
          status: peopleStatus,
        } = await supabase
          .from("people")
          .select(`*`)
          .eq("email", user?.email)
          .single();
        if (peopleError && peopleStatus !== 406) {
          throw userError;
        }

        let {
          data: domainData,
          error: domainError,
          status: domainStatus,
        } = await supabase
          .from("domains")
          .select(`*`)
          .eq("domain", domain_name)
          .single();
        if (domainError && domainStatus !== 406) {
          throw domainError;
        }
        //if first login and domain not built yet, build it
        if (!domainData) {
          const { data: domainData } = await supabase
            .from("domains")
            .upsert([
              {
                domain: domain_name,
                customer_id:
                  userData.impersonated_customer_id || userData.customer_id,
                first_login: true,
              },
            ])
            .select();
        }

        // if first login and customer_id not set yet, set it to domain customer_id
        if (userData.customer_id === null) {
          console.log("setting customer_id");
          const { data: userData } = await supabase
            .from("users")
            .update({
              customer_id: domainData.customer_id,
            })
            .eq("id", user?.id)
            .single()
            .select();
        }

        let {
          data: customerData,
          error: customerError,
          status: customerStatus,
        } = await supabase
          .from("customers")
          .select(`*`)
          .eq("id", userData.impersonated_customer_id || domainData.customer_id)
          .single();
        if (customerError && customerStatus !== 406) {
          throw customerError;
        }
        if (!customerData) {
          throw new Error("Customer not found");
        } else {
          setProfile({
            roles: roles,
            user: { ...userData, ...peopleData, ...user },
            customer: { ...customerData },
            domain: { ...domainData },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    getUserInfo()
  }, [user]);

  return profile;
};
