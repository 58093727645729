import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState, useEffect } from "react";
import SimpleBackDrop from "../../components/SimpleBackDrop";

import { Box, TextField } from "@mui/material";
import { useProfile } from "utils/profile";

const Probe = () => {
  const [probeInfo, setProbeInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const profile = useProfile();
  const user = profile.user;
  const supabase = useSupabaseClient();

  useEffect(() => {
    if (user) {
      getProbe();
    }
  }, [user]);

  const handleProbeInfoChange = (field) => (e) => {
    setProbeInfo({
      ...probeInfo,
      [field]: e.target.value,
    });
  };

  async function getProbe() {
    setIsLoading(true);
    let { data, error } = await supabase
      .from("probes")
      .select(`*`)
      .eq("customer_id", profile.customer.id);
    if (data !== null) {
      setIsLoading(false);
      setProbeInfo(data[0]);
    } else {
      setIsLoading(false);
      setProbeInfo({
        name: "",
        ip_address: "",
        key: "",
      });
    }
  }

  if (isLoading) {
    return <SimpleBackDrop />;
  }

  return (
    <>
      <Box m="30px 30px">
        <h1>NetSleuth Probe Information</h1>
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <TextField
            label="Probe Name"
            value={probeInfo?.name}
            onChange={handleProbeInfoChange("name")}
            fullWidth
            margin="normal"
          />
          <TextField
            label="IP Address"
            value={probeInfo?.ip_address}
            onChange={handleProbeInfoChange("ip_address")}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Access Key"
            value={probeInfo?.key}
            onChange={handleProbeInfoChange("key")}
            fullWidth
            margin="normal"
          />
        </Box>
      </Box>
    </>
  );
};

export default Probe;
