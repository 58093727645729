import Header from "../../components/Header";
import {
  Alert,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState, useEffect, useCallback } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  EditOutlined,
  PreviewOutlined,
  DeleteForeverOutlined,
  ForwardToInboxOutlined,
  CloseRounded,
  EditNotificationsOutlined,
} from "@mui/icons-material";
import { useProfile } from "utils/profile";
import SimpleBackDrop from "../../components/SimpleBackDrop";

const RiskRegister = () => {
  const theme = useTheme();
  const [risks, setRisks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const profile = useProfile();
  const user = profile.user;
  const supabase = useSupabaseClient();

  let rows = [];

  useEffect(() => {
    if (user) {
      getRisks();
    }
  }, [user]);

  const updaterow = (params) => {
    setEditData(params?.row);
    console.log(editData);
    setOpen(true);
  };

  const addrow = () => {
    setEditData({
      id: risks.length + 1,
      name: "",
      notes: "",
      status: "",
      likelihood: "",
      impact: "",
      loe: "",
    });
    console.log(editData);
    setOpen(true);
  };

  function deleterow() {
    console.log("deleting ", editData);
    getRisks();
  }

  const renderButton = (params) => {
    return (
      <>
        {params.field === "editbutton" ? (
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => updaterow(params)}
          >
            <EditOutlined />
          </IconButton>
        ) : null}

        {params.field === "deletebutton" ? (
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => deleterow(params)}
          >
            <DeleteForeverOutlined />
          </IconButton>
        ) : null}
      </>
    );
  };

  function saveContent() {
    console.log("saving ", editData);
    supabase
      .from("risks")
      .upsert({
        customer_id: profile.customer.id,
        id: editData.db_id,
        name: editData.name,
        notes: editData.notes,
        status: editData.status,
        modified_by: profile.user.email,
        last_modified_at: "now()",
        likelihood: editData.likelihood,
        impact: editData.impact,
        loe: editData.loe,
      })
      .select()
      .then((data) => {})
      .catch((err) => {
        console.log(err);
      });
    getRisks();
    setOpen(false);
  }

  const handleInfoChange = (field) => (e) => {
    setEditData({
      ...editData,
      [field]: e.target.value,
    });
  };

  async function getRisks() {
    setIsLoading(true);
    let { data, error } = await supabase
      .from("risks")
      .select(`*`)
      .eq("customer_id", profile.customer.id)
      .select(`*, groups(name)`);
    if (data !== null) {
      let i = 0;
      rows = [];
      data.map((risk) => {
        i = i + 1;
        rows = [
          {
            id: i,
            db_id: risk.id,
            name: risk.name,
            likelihood: risk.likelihood,
            impact: risk.impact,
            loe: risk.loe,
            notes: risk.notes,
            status: risk.status,
            modified_by: risk.modified_by,
            created_by: risk.added_by,
            group: risk.groups?.name,
            last_modified_at:
              risk.last_modified_at?.slice(5, 10) +
                "-" +
                risk.last_modified_at?.slice(0, 4) || "",
            created_at:
              risk.created_at.slice(5, 10) +
                "-" +
                risk.created_at.slice(0, 4) || "",
            last_reviewed:
              risk.last_reviewed?.slice(5, 10) +
                "-" +
                risk.last_reviewed?.slice(0, 4) || "",
            priority: (
              (risk.likelihood * risk.impact * (5 - risk.loe)) /
              10
            ).toFixed(0),
          },
          ...rows,
        ];
      });
      setRisks(rows);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log(error);
    }
  }

  const columns = [
    {
      field: "editbutton",
      headerName: "Edit",
      width: 40,
      renderCell: renderButton,
    },
    {
      field: "deletebutton",
      headerName: "Delete",
      width: 75,
      renderCell: renderButton,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 500,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 125,
    },
    {
      field: "group",
      headerName: "Focus Group",
      flex: 225,
    },

    {
      field: "likelihood",
      headerName: "Likelihood",
      type: "number",
      flex: 125,
    },
    {
      field: "impact",
      headerName: "Impact",
      type: "number",
      flex: 40,
    },
    {
      field: "loe",
      headerName: "Effort",
      type: "number",
      flex: 75,
    },
    {
      field: "priority",
      headerName: "Priority",
      type: "number",
      flex: 125,
    },
    {
      field: "created_at",
      headerName: "Created",
      flex: 150,
    },
    {
      field: "last_modified_at",
      headerName: "Last Modified",
      flex: 150,
    },
    {
      field: "last_reviewed",
      headerName: "Last Reviewed",
      flex: 150,
    },
  ];

  if (isLoading) {
    return <SimpleBackDrop />;
  }

  return (
    <>
      <Box>
        <Dialog
          maxWidth="sm"
          margin="2px"
          PaperProps={{
            sx: {
              minHeight: "10vh",
            },
          }}
          open={open}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <DialogTitle
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Edit mode
            </DialogTitle>
            <IconButton color="primary" onClick={() => saveContent()}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Box m={2}>
            <TextField
              label="Risk"
              fullWidth
              multiline
              rows={5}
              value={editData.name || ""}
              onChange={handleInfoChange("name")}
            />
            <Box mt={2}>
              <FormControl>
                <Select
                  value={editData.status || ""}
                  onChange={handleInfoChange("status")}
                  sx={{ mr: 2 }}
                  autoWidth
                >
                  <MenuItem value="Identified">Identified</MenuItem>
                  <MenuItem value="Needs Review">Needs Review</MenuItem>
                  <MenuItem value="Planned">Planned</MenuItem>
                  <MenuItem value="In-Progress">In-Progress</MenuItem>
                  <MenuItem value="Resolved">Resolved</MenuItem>
                  <MenuItem value="Deferred">Deferred</MenuItem>
                </Select>
                <FormHelperText>Status</FormHelperText>
              </FormControl>
              <FormControl>
                <Select
                  value={editData.likelihood || ""}
                  onChange={handleInfoChange("likelihood")}
                  sx={{ width: 75, mr: 2 }}
                >
                  <MenuItem value="1">1 - Low</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5 - High</MenuItem>
                </Select>
                <FormHelperText>Likelihood</FormHelperText>
              </FormControl>
              <FormControl>
                <Select
                  value={editData.impact || ""}
                  onChange={handleInfoChange("impact")}
                  sx={{ width: 75, mr: 2 }}
                >
                  <MenuItem value="1">1- Low</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5 - High</MenuItem>
                </Select>
                <FormHelperText>Impact</FormHelperText>
              </FormControl>
              <FormControl>
                <Select
                  value={editData.loe || ""}
                  onChange={handleInfoChange("loe")}
                  sx={{ width: 75 }}
                >
                  <MenuItem value="1">1 - Easy</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5 - Hard</MenuItem>
                </Select>
                <FormHelperText>Effort</FormHelperText>
              </FormControl>
            </Box>

            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={5}
              value={editData.notes || ""}
              onChange={handleInfoChange("notes")}
              margin="normal"
            />
            <Box border={1} borderRadius={1} p={2} mb={2}>
              <Box>
                Date Added: {editData.created_at}
                &nbsp;by {editData.created_by}
              </Box>
              <Box>
                Last Modified: {editData.last_modified_at}
                &nbsp;by {editData.modified_by}
              </Box>
              <Box>Last Reviewed: {editData.last_reviewed}</Box>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <Box m="20px">
        <Header title="Risk Register" />
        <Box m="40px 0 0 0" height="75vh">
          <Button
            sx={{ margin: "10px" }}
            color="primary"
            variant="contained"
            onClick={() => addrow()}
          >
            Add a Risk
          </Button>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "priority", sort: "desc" }],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            rows={risks}
            columns={columns}
            rowHeight={32}
          />
        </Box>
      </Box>
    </>
  );
};

export default RiskRegister;
