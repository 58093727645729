import React, { useState, useContext, useEffect } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  AccountCircle,
  ChevronLeft,
} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  FormControl,
  Select,
} from "@mui/material";

import { ColorModeContext } from "../theme";
import { supabase } from "../supabase";
import { tokens } from "../theme";
import { useProfile } from "utils/profile";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  
  const [customers, setCustomers] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const profile = useProfile();
  let user = profile.user;

  useEffect(() => {
    if (user) {
      loadCustomers();
    }
  }, [user]);

  const handleLogout = () => {
    supabase.auth.signOut();
  }

  async function loadCustomers() {
    let {
      data: customerData,
      error: customerError,
      status: customerStatus,
    } = await supabase.from("customers").select(`*`);
    if (customerError && customerStatus !== 406) {
      throw customerError;
    }
    if (customerData) {
      let menuitems = [];
      customerData.map((customer) => {
        menuitems = [
          {
            id: customer.id,
            name: customer.name,
          },
          ...menuitems,
        ];
      });
      setCustomers(menuitems);
    }
  }

  async function handleCustomerChange(id) {
    try {
      let {
        data: customerData,
        error: customerError,
        status: customerStatus,
      } = await supabase
        .from("users")
        .update({ impersonated_customer_id: id })
        .eq("id", user.id);
      if (customerError && customerStatus !== 406) {
        throw customerError;
      }
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            {!isSidebarOpen ? <MenuIcon /> : <ChevronLeft />}
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
          {user?.roles?.includes("superuser") ? (
            //pulldown with list of companys, onchange setImpersonate to companyid (save to DB)
            <Box
              m="3px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography m={2} color="primary">
                Impersonate Customer:{" "}
              </Typography>
              <FormControl size="small">
                <Select
                  value={profile.customer.id}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                >
                  {customers?.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton>

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
                color: colors.grey[100],
              }}
            >
              <AccountCircle sx={{ fontSize: "25px" }} />
              <Box textAlign="left">
                <Typography fontWeight="bold" fontSize="0.85rem">
                  {user?.full_name} 
                </Typography>
              </Box>
              <ArrowDropDownOutlined sx={{ fontSize: "25px" }} />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
