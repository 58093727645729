import { Dialog, DialogTitle } from "@mui/material";
import Header from "../../components/Header";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { Box, Button, IconButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import {
  InputOutlined,
  EditOutlined,
  PublishedWithChangesOutlined,
  PreviewOutlined,
  CloseRounded,
  PictureAsPdf,
  UploadFile,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";

import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import html2pdf from "html2pdf.js";
import SimpleBackDrop from "../../components/SimpleBackDrop";
import { useProfile } from "utils/profile";
import { FileUploader } from "react-drag-drop-files";

export default function PolicyandProcedure() {
  const supabase = useSupabaseClient();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const profile = useProfile();
  const user = profile.user;
  console.log(profile.roles);

  useEffect(() => {
    if (user) {
      getPolicies();
    }
  }, [user, open]);

  useEffect(() => {
    if (name !== "" && editorState.getCurrentContent().getPlainText() !== "") {
      let raw = convertToRaw(editorState.getCurrentContent());
      saveContent(name, raw);
    }
  }, [editorState]);

  const openEditor = (name) => {
    supabase
      .from("policies")
      .select("json")
      .eq("name", name)
      .eq("customer_id", profile.customer.id)
      .then((data) => {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(data.data[0].json))
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setName(name);
    setOpen(true);
  };

  function saveContent(name, raw) {
    console.log("saving", name, raw);
    supabase
      .from("policies")
      .upsert({
        customer_id: profile.customer.id,
        name: name,
        json: JSON.stringify(raw),
        status: "Updated",
        modified_by: user.email,
        last_modified_at: "now()",
      })
      .select()
      .then((data) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  async function getPolicies() {
    let rows = [];
    let realrows = [];
    setIsLoading(true);
    supabase
      .from("policies")
      .select("*")
      .eq("customer_id", profile.customer.id)
      .then((data) => {
        realrows = data.data;

        supabase
          .from("policy_templates")
          .select("*")
          .filter("industries", "is", "null")
          .then((data) => {
            rows = data.data.map((row, index) => ({
              id: index,
              name: row.name,
              shortname:
                realrows.find((r) => r.name === row.name)?.shortname || row.shortname,

              status:
                realrows.find((r) => r.name === row.name)?.status || "Missing",
              owner: realrows.find((r) => r.name === row.name)?.owner || "",
              created_by:
                realrows.find((r) => r.name === row.name)?.created_by || "",
              created_at:
                realrows
                  .find((r) => r.name === row.name)
                  ?.created_at.slice(5, 10) +
                  "-" +
                  realrows
                    .find((r) => r.name === row.name)
                    ?.created_at.slice(0, 4) || "",
              modified_by:
                realrows.find((r) => r.name === row.name)?.modified_by || "",
              last_modified_at:
                realrows
                  .find((r) => r.name === row.name)
                  ?.last_modified_at.slice(5, 10) +
                  "-" +
                  realrows
                    .find((r) => r.name === row.name)
                    ?.last_modified_at.slice(0, 4) || "",
            }));
            setRows(rows);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  }

  async function exporttoPDF(name) {
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    var opt = {
      margin: 0.5,
      filename: name + ".pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    const html = draftToHtml(rawContentState);
    html2pdf().from(html, "string").set(opt).save();
  }

  async function loadDocX(name, file) {
    var reader = new FileReader();
    var mammoth = require("mammoth");

    var options = {
      styleMap: [
        "p[style-name='Title'] => h1:fresh",
        "p[style-name='TOCHeading'] => h2:fresh",
        "p[style-name='TOC1'] => h3:fresh",
        "p[style-name='TOC2'] => h4:fresh",
        "p[style-name='TOC3'] => h5:fresh",
      ],
    };
    reader.onload = function (loadEvent) {
      var arrayBuffer = loadEvent.target.result;
      mammoth
        .convertToHtml({ arrayBuffer: arrayBuffer }, options)
        .then(function (resultObject) {
          var html = resultObject.value;
          console.log("errors:", resultObject.messages);
          const editorState = EditorState.createWithContent(
            stateFromHTML(html)
          );
          setEditorState(editorState);
        });
    };
    reader.readAsArrayBuffer(file);
    setLoad(false);
  }

  const renderButton = (params) => {
    return (
      <>
        {params.field === "editbutton" ? (
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => openEditor(params.row.name)}
          >
            <EditOutlined />
          </IconButton>
        ) : null}

        {params.field === "loadbutton" ? (
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => loadTemplate(params.row.name, params.row.status)}
          >
            <InputOutlined />
          </IconButton>
        ) : null}

        {params.field === "approvebutton" ? (
          <IconButton
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => markApprove(params.row.name)}
          >
            <PublishedWithChangesOutlined />
          </IconButton>
        ) : null}

        {params.field === "reviewbutton" ? (
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => markReview(params.row.name)}
          >
            <PreviewOutlined />
          </IconButton>
        ) : null}
      </>
    );
  };

  const loadTemplate = async (name,status) => {
    if (status === "Missing") {
    supabase
      .from("policy_templates")
      .select("*")
      .eq("name", name)
      .then((data) => {
        if (data.data[0]) {
          supabase
            .from("policies")
            .upsert({
              customer_id: profile.customer.id,
              name: name,
              json: data.data[0].json,
              status: "Imported",
              modified_by: user.email,
              last_modified_at: "now()",
            })
            .then((data) => {
              console.log("saved", data);
              getPolicies();
            });
        }
      });
    }
  };

  const markReview = async () => {};

  const markApprove = async () => {};

  const loadMissingTemplates = async () => {
    supabase
      .from("policy_templates")
      .select("*")
      .then((data) => {
        console.log("found data", data.data);
        data.data.map((row, index) =>
          supabase
            .from("policies")
            .select("*")
            .eq("name", row.name)
            .eq("customer_id", profile.customer.id)
            .then((data) => {
              if (!data.data[0]) {
                console.log("found missing", row.name);
                loadTemplate(row.name);
              }
            })
        );
      });
    getPolicies();
  };

  const markAllReview = async () => {};

  const markAllApprove = async () => {};

  const columns = [
    {
      field: "editbutton",
      headerName: "Edit",
      width: 40,
      renderCell: renderButton,
    },
    {
      field: "loadbutton",
      headerName: "Load",
      width: 50,
      renderCell: renderButton,
    },
    {
      field: "reviewbutton",
      headerName: "Review",
      width: 50,
      renderCell: renderButton,
    },
    {
      field: "approvebutton",
      headerName: "Approve",
      width: 65,
      renderCell: renderButton,
    },
    { field: "shortname", headerName: "ID", width: 75 },
    { field: "name", headerName: "Policy Name", width: 250 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "owner", headerName: "Owner", width: 150 },
    { field: "created_at", headerName: "Created", width: 100 },
    { field: "last_modified_at", headerName: "Last Modified", width: 100 },
    { field: "modified_by", headerName: "Modified By", width: 150 },
    { field: "next_review", headerName: "Next Review", width: 100 },
  ];

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[500],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.secondary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.secondary.main,
            ODD_OPACITY - 0.1
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  if (isLoading) {
    return <SimpleBackDrop />;
  }

  const fileTypes = ["DOCX"];

  return (
    <>
      <Box>
        <Dialog
          maxWidth="sm"
          margin="2px"
          PaperProps={{
            sx: {
              minHeight: "10vh",
            },
          }}
          open={load}
        >
          <IconButton onClick={() => setLoad(false)}>
            <CloseRounded />
          </IconButton>
          <Box m={2}>
            <FileUploader
              handleChange={(file) => loadDocX(name, file)}
              name="documents"
              types={fileTypes}
            />
          </Box>
        </Dialog>
      </Box>
      <Box>
        <Dialog
          maxWidth="lg"
          PaperProps={{
            sx: {
              minHeight: "80vh",
            },
          }}
          open={open}
          onClose={() => saveContent(name, editorState.getCurrentContent())}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <DialogTitle
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {name} :: Edit mode
            </DialogTitle>
            <IconButton color="secondary" onClick={() => setLoad(true)}>
              <UploadFile />
            </IconButton>
            <IconButton color="secondary" onClick={() => exporttoPDF(name)}>
              <PictureAsPdf />
            </IconButton>
            <IconButton color="secondary" onClick={() => setOpen(false)}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Box m={2}>
            <Editor
              spellCheck={true}
              //customStyleMap={styleMap}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                options: [
                  "inline",
                  "fontFamily",
                  "fontSize",
                  "colorPicker",
                  "textAlign",
                  "blockType",
                  "list",
                  "link",
                  "image",
                  "history",
                ],
                inline: {
                  inDropdown: false,
                  options: ["bold", "italic", "strikethrough", "underline"],
                },
                blockType: {
                  options: [
                    "Normal",
                    "H1",
                    "H2",
                    "H3",
                    "H4",
                    "H5",
                    "H6",
                    "Code",
                  ],
                },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
              }}
            />
          </Box>
        </Dialog>
      </Box>
      <Box m="20px">
        <Header title="Policy and Procedure Library" />
      </Box>
      <Box m="30px 30px" height="75vh" width="80vw">
        <Box m="30px 30px"></Box>
        <Box height="75vh">
          {profile.roles?.includes("admin") ? (
            <>
              <Button
                sx={{ margin: "10px" }}
                color="primary"
                variant="contained"
                onClick={loadMissingTemplates}
              >
                Load Templates for Missing
              </Button>
              <Button
                sx={{ margin: "10px" }}
                color="primary"
                variant="contained"
                onClick={markAllReview}
              >
                Mark All for Review
              </Button>
              <Button
                sx={{ margin: "10px" }}
                color="primary"
                variant="contained"
                onClick={markAllApprove}
              >
                Mark All for Approval
              </Button>
              <Button
                sx={{ margin: "10px" }}
                color="primary"
                variant="contained"
                onClick={markAllApprove}
              >
                Save All to Document Library
              </Button>
            </>
          ) : null}

          {profile.roles?.includes("superuser") ? (
            <Button
              sx={{ margin: "10px" }}
              color="primary"
              variant="contained"
              onClick={markAllApprove}
            >
              Save All to Templates
            </Button>
          ) : null}
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "shortname", sort: "asc" }],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            rows={rows}
            columns={columns}
            rowHeight={32}
          />
        </Box>
      </Box>
    </>
  );
}
